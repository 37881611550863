import React from 'react';
import {
  CommonGrid,
  GlobalWrapper,
  Button,
} from '../../shared-styled-components/globals.styled';
import * as el from './GrabAcoffee.styled';
import { Heading, P } from '../../shared-styled-components/typography';
import coffeeIMG from '../../../images/Hero_3.jpg';

export default function GrabACoffee() {
  return (
    <GlobalWrapper smFullWidth>
      <CommonGrid as="section">
        <el.InfoContainer>
          <Heading spacedOut medium>
            Interviews are awkward <br /> Coffee is awesome
          </Heading>
          <P margin="40px 0" spacedOut large>
            Want to get a feel for what it's like to work here? The coffee's on
            us. Every month, new team members will be sharing their insights and
            their coffee cards.
          </P>
          <Button>grab a coffee</Button>
        </el.InfoContainer>
        <el.Image bgImg={coffeeIMG} />
      </CommonGrid>
    </GlobalWrapper>
  );
}
