import React, { useState } from 'react';
import * as el from './OurMindset.styled';
import {
  CommonGrid,
  GlobalWrapper,
} from '../../shared-styled-components/globals.styled';

import home1 from '../../../images/Hero_1.jpg';
import home2 from '../../../images/Hero_2.jpg';
import home3 from '../../../images/Hero_3.jpg';
import home4 from '../../../images/Hero_4.jpg';
import Carousel from '../../Carousel/Carousel.component';
import { Heading } from '../../shared-styled-components/typography';
import Accordion from '../../Accordion/Accordion.component';

export default function OurMindset() {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

  const carouselData = [
    {
      bgImg: home1,
    },
    {
      bgImg: home2,
    },
    {
      bgImg: home3,
    },
    {
      bgImg: home4,
    },
  ];

  const leftAccordions = [
    {
      heading: 'Speak with people, not for people',
      content:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam rerum architecto tempore sunt, optio consectetur.',
    },
    {
      heading: 'Call the question',
      content:
        'lolz Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam rerum architecto tempore sunt, optio consectetur.',
    },
    {
      heading: 'Make space to discover',
      content:
        'lolz Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam rerum architecto tempore sunt, optio consectetur.',
    },
  ];

  const rightAccordions = [
    {
      heading: 'Be comfortable with the uncomfortable',
      content:
        'lolz Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam rerum architecto tempore sunt, optio consectetur.',
    },
    {
      heading: "Show, don't tell",
      content:
        'lolz Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam rerum architecto tempore sunt, optio consectetur.',
    },
    {
      heading: 'Make others successful',
      content:
        'lolz Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam rerum architecto tempore sunt, optio consectetur.',
    },
    {
      heading: 'Be optimistic',
      content:
        'lolz Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam rerum architecto tempore sunt, optio consectetur.',
    },
  ];
  // const accordionContext = useContext(AccordionContext);

  return (
    <GlobalWrapper smFullWidth>
      <CommonGrid rowSize="100px" as="section">
        <el.CarouselContainer>
          <Carousel slides={carouselData} />
        </el.CarouselContainer>
        <el.MindsetInfoContainer>
          <Heading capitalized>Our mindset</Heading>
          <el.FlexRow>
            <el.FlexCol>
              {leftAccordions.map((accordion, i) => (
                <el.ValueItem key={i}>
                  <Accordion
                    id={i}
                    openIndex={openAccordionIndex}
                    setOpenIndexHandler={setOpenAccordionIndex}
                    heading={accordion.heading}
                  >
                    {accordion.content}
                  </Accordion>
                </el.ValueItem>
              ))}
            </el.FlexCol>
            <el.FlexCol>
              {rightAccordions.map((accordion, i) => (
                <el.ValueItem key={i}>
                  <Accordion
                    id={i + 3}
                    openIndex={openAccordionIndex}
                    setOpenIndexHandler={setOpenAccordionIndex}
                    heading={accordion.heading}
                  >
                    {accordion.content}
                  </Accordion>
                </el.ValueItem>
              ))}
            </el.FlexCol>
          </el.FlexRow>
        </el.MindsetInfoContainer>
      </CommonGrid>
    </GlobalWrapper>
  );
}
