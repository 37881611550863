import styled from 'styled-components';
import * as vars from '../../../constants';

export const InfoContainer = styled.div`
  background-color: ${vars.branding.grey2};
  grid-row: 3 / span 9;
  grid-column: 1;
  padding: 10px;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 2 / span 9;
    grid-column: 1 / span 5;
    padding: 25% 5% 0 25%;
  }
`;

export const Image = styled.div`
  grid-row: 1 / span 2;
  grid-column: 1;
  z-index: 10;
  background: url(${p => p.bgImg}) center center no-repeat;
  background-size: cover;

  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 1 / span 12;
    grid-column: 6 / span 7;
  }
`;
