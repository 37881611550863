import styled from 'styled-components';
import * as vars from '../../../constants';

export const WhatWeDoContainer = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: ${vars.breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 100px);
    grid-gap: ${vars.gridGutter};
  }
`;

export const Cell1 = styled.div`
  grid-row: 1 / span 2;
  grid-column: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 1 / span 2;
    grid-column: 1 / span 6;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 1 / span 2;
    grid-column: 1 / span 4;
  }
`;

export const Cell2 = styled.div`
  grid-row: 3 / span 2;
  grid-column: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 1 / span 2;
    grid-column: 7 / span 6;
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 2 / span 2;
    grid-column: 5 / span 6;
  }
`;

export const Cell3 = styled.div`
  grid-row: 5 / span 2;
  grid-column: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 3 / span 3;
    grid-column: 1 / span 4;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 3 / span 3;
    grid-column: 5 / span 2;
  }
`;

export const Cell4 = styled.div`
  grid-row: 7 / span 2;
  grid-column: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 3 / span 3;
    grid-column: 5 / span 4;
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 3 / span 3;
    grid-column: 7 / span 2;
  }
`;

export const Cell5 = styled.div`
  grid-row: 9 / span 2;
  grid-column: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 3 / span 3;
    grid-column: 9 / span 4;
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 3 / span 3;
    grid-column: 9 / span 2;
  }
`;

export const Cell6 = styled.div`
  grid-row: 11 / span 2;
  grid-column: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 6 / span 3;
    grid-column: 1 / span 4;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 6 / span 3;
    grid-column: 5 / span 2;
  }
`;

export const Cell7 = styled.div`
  grid-row: 13 / span 2;
  grid-column: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 6 / span 3;
    grid-column: 5 / span 4;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 6 / span 3;
    grid-column: 7 / span 2;
  }
`;

export const Cell8 = styled.div`
  grid-row: 15 / span 2;
  grid-column: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 6 / span 3;
    grid-column: 9 / span 4;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 6 / span 3;
    grid-column: 9 / span 2;
  }
`;
