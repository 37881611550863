import styled from 'styled-components';
import * as vars from '../../../constants';

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 60px;
  width: 300px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  flex-direction: column;
  @media (min-width: ${vars.breakpoints.md}) {
    flex-direction: row;
  }
`;
