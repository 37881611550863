import React, { useRef, useState } from 'react';
import * as el from './Accordion.styled';
export default function Accordion(props) {
  const accordionContentRef = useRef(null);

  const [localAccordionIsOpen, localSetAccordionIsOpen] = useState(false); // if no parent index and index setter present, this state will take over
  const [contentHeight, setContentHeight] = useState('0px');

  const toggleAccordion = () => {
    const contentOffsetHeight = `${accordionContentRef.current.offsetHeight}px`;
    props.setOpenIndexHandler
      ? props.setOpenIndexHandler(props.id)
      : localSetAccordionIsOpen(!localAccordionIsOpen);
    setContentHeight(contentOffsetHeight);
  };

  return (
    <el.AccordionContainer>
      <el.AccordionHeaderBtn
        selected={
          props.setOpenIndexHandler
            ? props.openIndex === props.id
            : localAccordionIsOpen
        }
        onClick={toggleAccordion}
      >
        <el.NumberListing>
          {props.id < 10 ? `0${props.id + 1} ` : props.id + ' '}
        </el.NumberListing>
        {props.heading}
      </el.AccordionHeaderBtn>
      <el.AccordionContentContainer
        open={
          props.setOpenIndexHandler
            ? props.openIndex === props.id
            : localAccordionIsOpen
        }
        contentHeight={contentHeight}
      >
        <el.AccordionContent ref={accordionContentRef}>
          {props.children}
        </el.AccordionContent>
      </el.AccordionContentContainer>
    </el.AccordionContainer>
  );
}
