import styled from 'styled-components';
import * as vars from '../../../constants';

export const CarouselContainer = styled.div`
  grid-column: 2 / span 10;
  grid-row: 1 / span 8;
  z-index: 10;
`;

export const MindsetInfoContainer = styled.div`
  background: ${vars.branding.grey2};
  grid-column: 1 / span 12;
  grid-row: 5 / span 8;
  padding: 400px 150px 50px 150px;
`;

export const ValueItem = styled.div`
  width: 275px;
  margin-top: 10px;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (min-width: ${vars.breakpoints.md}) {
    flex-direction: row;
  }
`;
