import styled from 'styled-components';
import * as vars from '../../constants';

export const AccordionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AccordionHeaderBtn = styled.button`
  border: none;
  background: transparent;
  font-size: 1.5rem;
  text-align: left;
  padding: 10px 0;
  display: flex;
  align-items: flex-start;
  color: ${p => (p.selected ? vars.branding.primary : vars.branding.secondary)};
  transition: color 250ms ease-out;
  &:hover {
    color: ${vars.branding.primary};
    cursor: pointer;
  }
`;

export const AccordionContentContainer = styled.div`
  max-height: ${p => (p.open ? p.contentHeight : 0)};
  overflow: hidden;
  transition: max-height 200ms ease-out;
`;

export const AccordionContent = styled.div``;

export const NumberListing = styled.span`
  font-size: 1.15rem;
  margin-right: 10px;
`;
